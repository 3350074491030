import Cookies from 'js-cookie';
import md5 from 'spark-md5';
import Emitter from 'component-emitter';
import config from './config';
import { hashDataAttr, isPushNotificationsSupported } from './utils';
import Analytics from './Analytics';
import ExitIntent from './ExitIntent';
import Runner from './Runner';

const core = {
  globalName: 'mt',
};

core.runner = new Runner();
core.analytics = new Analytics(config.event_tracking_api);
core.emitter = new Emitter();

core.getPageUrl = function () {
  return window.location.href;
};

core.gaEvent = function (action, campaignId, campaignName) {
  if (!core.send_ga) {
    return;
  }

  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: `maxtraffic_${action}`,
      campaign_id: campaignId,
      campaign_name: campaignName,
    });

    if (window.gtag) {
      window.gtag('event', `maxtraffic_${action}`, {
        mt_campaign_id: campaignId,
        mt_campaign_name: campaignName,
      });
    }
  } catch (e) {
    console.log(`ga error : ${e.message}`);
  }
};

core.getHostname = function (url) {
  const a = document.createElement('a');
  a.href = url;
  return a.hostname;
};

core.getDomainName = function () {
  let i = 0;
  let { domain } = document;
  const p = domain.split('.');
  const s = `_gd${new Date().getTime()}`;
  while (i < p.length - 1 && document.cookie.indexOf(`${s}=${s}`) == -1) {
    domain = p.slice(-1 - ++i).join('.');
    document.cookie = `${s}=${s};domain=${domain};`;
  }
  document.cookie = `${s}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${domain};`;
  return domain;
};

core.addEvent = function (obj, evt, fn, useCapture) {
  if (obj.addEventListener) {
    obj.addEventListener(
      evt,
      fn,
      typeof useCapture !== 'undefined' ? false : useCapture,
    );
  } else if (obj.attachEvent) {
    obj.attachEvent(`on${evt}`, fn);
  }
};

core.getWindowSize = function () {
  let e = 0;
  let n = 0;
  return (
    window.innerWidth
      ? ((e = window.innerWidth), (n = window.innerHeight))
      : document.documentElement.clientWidth != 0
      ? ((e = document.documentElement.clientWidth),
        (n = document.documentElement.clientHeight))
      : ((e = document.body.clientWidth), (n = document.body.clientHeight)),
    {
      width: e,
      height: n,
    }
  );
};

core.getHeight = function () {
  const { body } = document;
  const html = document.documentElement;

  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );
};

core.domHash = function (name, id) {
  return `mt-${md5
    .hash(
      `${name}-${core.website_id}${typeof id !== 'undefined' ? `-${id}` : ''}`,
    )
    .substr(0, 16)}`;
};

core.setStyle = function (e, p, v) {
  e.style.setProperty(p, v, 'important');
};

core.removeStyle = function (e, p) {
  e.style.removeProperty(p);
};

core.syncContactId = function () {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const contactId = urlParams.get('mtcid');
  if (contactId && contactId !== core.contactId) {
    core.contactId = contactId;
    core.analytics.identify(core.websiteId, core.uid, core.contactId);
    core.writeIdentityCookie();
  }
};

core.setUID = function () {
  Cookies.remove('maxti', { domain: core.options.cookie_domain });

  const visit_cookie = Cookies.get('maxtv');
  const fv_cookie = Cookies.get('maxtf');
  const [anonymousId, contactId] = core.readIdentityCookie();
  core.uid = anonymousId;
  core.contactId = contactId;

  if (!core.uid) {
    core.uid = core.generateUid();
    core.writeIdentityCookie();
  }

  // Check if first visit else increment visit counter
  if (fv_cookie) {
    core.visitCount = visit_cookie
      ? parseFloat(fv_cookie)
      : parseFloat(fv_cookie) + 1;
  }

  Cookies.set('maxtf', core.visitCount, {
    expires: (60 * 30) / (24 * 60 * 60),
    domain: core.options.cookie_domain,
  });

  // Define visit time cookie
  if (visit_cookie) {
    core.visit = visit_cookie;
  } else {
    Cookies.set('maxtp', '0:0', {
      expires: 365,
      domain: core.options.cookie_domain,
    });
    Cookies.set('maxtv', core.visit, {
      expires: (60 * 30) / (24 * 60 * 60),
      domain: core.options.cookie_domain,
    });
  }
};

core.readIdentityCookie = function () {
  const maxtu = Cookies.get('maxtu');
  if (!maxtu) {
    return [null, null];
  }
  const [anonymousId, contactId] = maxtu.split('.');

  return [anonymousId || null, contactId || null];
};

core.writeIdentityCookie = function () {
  Cookies.set(
    'maxtu',
    core.uid + (core.contactId ? `.${core.contactId}` : ''),
    {
      expires: 365,
      domain: core.options.cookie_domain,
    },
  );
};

core.inactive = function () {
  return Cookies.set('maxti', 1, {
    expires: (60 * 30) / (24 * 60 * 60),
    domain: core.options.cookie_domain,
  });
};

core.isInactive = function () {
  return !!Cookies.get('maxti');
};

core.generateUid = function () {
  let uid;
  const gaCookie = Cookies.get('_ga');
  if (gaCookie) {
    uid = md5.hash(`${core.website_id}.${gaCookie.split('.')[2]}`);
  } else {
    const S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    uid = S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4();
  }
  return uid;
};

core.setVisit = function () {
  if (core.pageview == 1) {
    Cookies.remove('maxtc', { domain: core.options.cookie_domain });
  }

  // Get pageview/visit time cookie
  const page_cookie = Cookies.get('maxtp') ? Cookies.get('maxtp') : '0:0';
  const page_arr = page_cookie.split(':');

  core.visitTime = page_arr[1];

  // Update pageview/visit time cookie
  Cookies.set('maxtp', `${core.pageview}:${core.visitTime}`, {
    expires: 30,
    domain: core.options.cookie_domain,
  });
};

core.updateTabs = function () {
  const timestamp = Math.round(+new Date() / 1000);

  localStorage.setItem(`mt_tab_${core.tabId}`, timestamp);

  core.activeTabs = 1;

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    // Tab key
    if (key.indexOf('mt_tab_') == 0 && key !== `mt_tab_${core.tabId}`) {
      const tabTimestamp = localStorage.getItem(key);

      if (timestamp - tabTimestamp > 5) {
        localStorage.removeItem(key);
      } else {
        core.activeTabs += 1;
      }
    }
  }
};

core.setImpression = function (campaign) {
  localStorage.setItem(
    `mt_impression_${Math.round(+new Date() / 1000)}`,
    campaign.id,
  );

  core.logPixel(
    `${core.options.url_impression}&campaign_id=${campaign.id}&creative_id=${
      campaign.creative_id
    }&pageview=${core.pageview}&url=${encodeURIComponent(core.getPageUrl())}`,
  );

  try {
    if (typeof core.onShow === typeof Function) {
      core.onShow();
    }
  } catch (e) {
    console.log(`core.onShow error : ${e.message}`);
  }
  core.gaEvent('view', campaign.id, campaign.name);
};

core.crossTabImpression = function (key, campaignId) {
  setTimeout(() => {
    localStorage.removeItem(key);
  }, 2000);

  core.campaigns.forEach((campaign, index) => {
    if (campaign.id == campaignId) {
      core.campaigns.splice(index, 1);
    }
  });
};

core.updateShopifyCart = function () {
  const cart = Cookies.get('cart');

  if (cart) {
    core.logPixel(`${core.options.url_cart}&cart_token=${cart}`);
  }

  const xhr = new XMLHttpRequest();
  xhr.open('POST', '/cart/update.js');
  xhr.setRequestHeader(
    'Accept',
    'application/json, text/javascript, */*; q=0.01',
  );
  xhr.setRequestHeader(
    'Content-Type',
    'application/x-www-form-urlencoded; charset=UTF-8',
  );
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  xhr.send(`attributes[__maxtu]=${core.uid}`);
};

core.getReferrer = function () {
  const ref = document.referrer;
  const ref_hostname = core.getHostname(ref);
  return ref_hostname.indexOf(core.options.website_domain) == -1 &&
    ref_hostname.indexOf(core.options.serve_domain) == -1
    ? ref
    : '';
};

core.getCampaigns = function () {
  let { url_init } = core.options;

  url_init += `&segments=${encodeURIComponent(core.segments.join(','))}`;
  url_init += `&maxtv=${core.visit}`;
  url_init += `&visit=${core.visitCount}`;
  url_init += `&url=${encodeURIComponent(core.getPageUrl())}`;
  url_init += `&ref=${encodeURIComponent(core.getReferrer())}`;

  core.segments = [];

  core.callScript(url_init);
};

core.cookieFilterPassed = function (campaign) {
  if (campaign.cookie_rules.length == 0) {
    return true;
  }

  let passed = true;

  campaign.cookie_rules.forEach((rule) => {
    if (!core.compare(Cookies.get(rule.name) || '', rule.value, rule.type)) {
      passed = false;
    }
  });

  return passed;
};

core.compare = function (string, value, method) {
  switch (method) {
    case 'EQUALS':
      return string == value;
      break;
    case 'CONTAINS':
      return string.includes(value);
      break;
    case 'STARTS_WITH':
      return string.startsWith(value);
      break;
    case 'ENDS_WITH':
      return string.endsWith(value);
      break;
    case 'NOT_EQUALS':
      return string != value;
      break;
    case 'NOT_CONTAINS':
      return !string.includes(value);
      break;
    case 'NOT_STARTS_WITH':
      return !string.startsWith(value);
      break;
    case 'NOT_ENDS_WITH':
      return !string.endsWith(value);
      break;
    case 'LESS_THAN':
      return core.toNumberOrLength(string) < core.toNumberOrLength(value);
      break;
    case 'LESS_EQUALS':
      return core.toNumberOrLength(string) <= core.toNumberOrLength(value);
      break;
    case 'GREATER_THAN':
      return core.toNumberOrLength(string) > core.toNumberOrLength(value);
      break;
    case 'GREATER_EQUALS':
      return core.toNumberOrLength(string) >= core.toNumberOrLength(value);
      break;
  }

  return false;
};

core.toNumberOrLength = function (value) {
  return core.isNumber(value) ? parseFloat(value) : value.length;
};

core.isNumber = function (value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
};

core.filterCampaigns = function (campaigns) {
  const filteredCampaigns = [];

  for (const index in campaigns) {
    if (campaigns.hasOwnProperty(index)) {
      const campaign = campaigns[index];

      if (
        campaigns[index].manual_trigger_id == null &&
        !core.cookieFilterPassed(campaign)
      ) {
        continue;
      }
      filteredCampaigns.push(campaign);
    }
  }

  return filteredCampaigns;
};

core.triggerManualCampaign = function (triggerId) {
  const triggered = Object.keys(core.activeCampaigns).map(
    (key) => core.activeCampaigns[key].manual_trigger_id,
  );

  if (triggered.indexOf(triggerId) !== -1) {
    return;
  }
  core.campaigns.forEach((c) => {
    if (c.manual_trigger_id === triggerId) {
      core.show(c);
    }
  });
};

core.initCampaigns = function (data) {
  core.campaigns = JSON.parse(data);

  core.campaigns = core.filterCampaigns(core.campaigns);

  // Preload and show campaign in preview mode
  if (core.preview) {
    const campaign = {
      id: 0,
      creative_id: core.preview,
    };
    core.insertCampaignFrame(campaign);
    creative.show(campaign);
    return;
  }

  // Load campaign iframe in preload container
  if (core.preloadEnabled) {
    core.campaigns.forEach((campaign) => {
      core.insertCampaignFrame(campaign);
    });
  }

  core.runner.disableQueue();
};

core.stopCampaigns = function () {
  core.campaigns = [];
};

core.trigger = function (type) {
  if (type == 'exit') {
    core.isExitIntent = true;
  }
};

core.removePreloadContainer = function () {
  const container = document.getElementById(core.domHash('preload-container'));
  if (container) {
    container.parentNode.removeChild(container);
  }
};

core.getPreloadContainer = function () {
  const id = core.domHash('preload-container');
  let container = document.getElementById(id);

  if (!container) {
    container = document.createElement('div');
    container.id = id;
    document.body.appendChild(container);
  }

  return container;
};

core.insertCampaignFrame = function (campaign) {
  let iframeUrl = `${core.options.url_serve}/${campaign.creative_id}/${core.device}/${core.revision}?campaign_id=${campaign.id}&uid=${core.uid}&lang=${core.language}`;

  if (campaign.end_timestamp) {
    iframeUrl += `&tte=${
      parseInt(campaign.end_timestamp) - Math.floor(Date.now() / 1000)
    }`;
  }

  const className = `mt-${campaign.type}`;

  const html = `<div style="z-index:-1!important; overflow:hidden; height: 1px!important; left: 0!important; position: fixed!important; top: -10px!important; width: 1px!important;" class="${className}" id="${core.domHash(
    'creative-container',
    campaign.id,
  )}"><div style="width: 100% !important; height: 100%!important; position: relative!important;"><iframe id="${core.domHash(
    'creative-iframe',
    campaign.id,
  )}" src="${iframeUrl}" style="display:block!important; width:100%!important; height:100%!important; z-index:${core.zIndex}!important; background-color:transparent!important;" allowtransparency="true" frameborder="0" scrolling="no" allowfullscreen></iframe></div></div>`;

  const container = core.getPreloadContainer();

  container.insertAdjacentHTML('beforeend', html);
};

core.logPageTime = function () {
  const visitTime = Math.round(
    parseFloat(core.visitTime) + parseFloat(core.seconds),
  );
  Cookies.set('maxtp', `${core.pageview}:${visitTime}`, {
    expires: 365,
    domain: core.options.cookie_domain,
  });
};

core.tryCampaigns = function () {
  const { campaigns } = core;
  core.seconds = Math.round(parseFloat(core.seconds + 0.1) * 100) / 100;

  for (const index in campaigns) {
    if (campaigns.hasOwnProperty(index)) {
      const campaign = campaigns[index];

      if (
        campaign.manual_trigger_id !== null ||
        core.activeCampaigns[campaign.id]
      ) {
        continue;
      }

      const after_seconds_site =
        campaign.after_seconds_site -
        (parseFloat(core.visitTime) + parseFloat(core.seconds));
      const after_seconds_page =
        campaign.after_seconds_page - parseFloat(core.seconds);

      if (after_seconds_site <= 0 && after_seconds_page <= 0) {
        let exitIntentCheck = campaign.traffic_source != 'exit';

        if (core.isExitIntent) {
          exitIntentCheck = true;
          core.isExitIntent = false;
        }

        if (exitIntentCheck) {
          core.show(campaign);
          break;
        }
      }
    }
  }
};

core.show = function (campaign) {
  core.activeCampaigns[campaign.id] = campaign;

  core.campaigns.forEach((c, index) => {
    // delete campaigns that can not are not allowed to show one more time
    if (
      c.manual_trigger_id === null &&
      (campaign.id == c.id || campaign.type == c.type)
    ) {
      delete core.campaigns[index];
    }
  });

  creative.bootstrap(campaign);

  core.emitter.emit('Campaign Viewed');
};

core.logPixel = function (src) {
  const timestamp = Math.round(+new Date() / 1000);
  const i = new Image();
  i.src = `${src}&t=${timestamp}&uid=${core.uid}`;
};

core.callScript = function (src) {
  setTimeout(() => {
    const script = document.createElement('script');
    script.src = `${src}&t=${Math.round(+new Date() / 1000)}&uid=${core.uid}`;
    script.async = true;

    const firstScript = document.getElementsByTagName('script')[0];

    firstScript.parentNode.insertBefore(script, firstScript);
  }, 100);
};

core.loadPushNotification = function (jsPath, cssPath, config) {
  if (!core.pushEnabled || !isPushNotificationsSupported()) {
    return;
  }

  mtPush('create', core.website_id, cssPath, core.device, config, core.config);

  // create a new script element
  const script = document.createElement('script');
  script.src = jsPath;
  script.async = true;

  // insert the script element into the document
  const firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode.insertBefore(script, firstScript);
};

core.logMousemove = function (event) {
  const mousePos = {
    x: event.clientX,
    y: event.clientY,
    t: +new Date(),
  };

  core.mouseMovement.unshift(mousePos);

  if (core.mouseMovement.length > 10) {
    core.mouseMovement.length = 10;
  }
};

core.messageHandler = function (event) {
  if (!core.validOrigin(event.origin)) {
    return;
  }

  let message;

  try {
    message = JSON.parse(event.data);
  } catch (e) {
    return;
  }

  if (!message || !message.action) {
    return;
  }

  switch (message.action) {
    case 'discount-code':
      core.discount.setDiscount(message.options.discount);
      break;
  }
};

const DiscountRibbon = function () {
  this.discount = null;
  this.storageKey = 'mt-discount-code';
  this.domElement = {
    container: null,
    icon: null,
    code: null,
    counter: null,
    style: null,
  };
  this.domId = {
    container: core.domHash('discount-container'),
    code: core.domHash('discount-code'),
    icon: core.domHash('discount-icon'),
    counter: core.domHash('discount-counter'),
    style: core.domHash('discount-style'),
  };
  this.timer = null;

  this.construct = function () {
    this.fetchFromStorage();
  };

  this.setDiscount = function (discount) {
    this.loadStylesheet();
    this.loadDom();

    this.discount = discount;

    if (discount.counter !== null && discount.counter > 0) {
      this.setDiscountCounter(discount);
    }

    if (discount.counter !== null && discount.counter <= 0) {
      this.removeDiscount(discount);
      return;
    }

    sessionStorage.setItem(this.storageKey, JSON.stringify(discount));

    this.updateDom(discount);
  };

  this.updateDom = function (discount) {
    let self = this;

    core.setStyle(
      this.domElement.container,
      'background',
      discount.ribbon.backgroundColor,
    );
    core.setStyle(
      this.domElement.container,
      'color',
      discount.ribbon.textColor,
    );
    core.setStyle(this.domElement.container, 'cursor', 'pointer');
    core.setStyle(this.domElement.icon, 'color', discount.ribbon.textColor);
    core.setStyle(this.domElement.code, 'color', discount.ribbon.textColor);
    core.setStyle(this.domElement.counter, 'color', discount.ribbon.textColor);

    if (discount.ribbon.position == 'right') {
      core.setStyle(this.domElement.container, 'right', discount.ribbon.offset);
      core.removeStyle(this.domElement.container, 'left');
    } else {
      core.setStyle(this.domElement.container, 'left', discount.ribbon.offset);
      core.removeStyle(this.domElement.container, 'right');
    }

    this.domElement.code.innerHTML = discount.code;

    this.domElement.container.className = 'open';

    this.domElement.container.onclick = async function () {
      await navigator.clipboard.writeText(discount.code);
      self.domElement.code.innerHTML = 'Copied';
      setTimeout(() => self.removeDiscount(), 2000);
    };

    this.preventOffscreen(this.domElement.container);
  };

  this.setDiscountCounter = function (discount) {
    const start = Date.now();
    const cd = 1000 * parseInt(discount.counter);
    const self = this;

    clearInterval(this.timer);
    this.timer = null;

    const counter = function () {
      const diff = Date.now() - start;
      const ms = cd - diff;

      const sec_num = ms / 1000;
      const hours = Math.floor(sec_num / 3600);
      const minutes = Math.floor((sec_num - hours * 3600) / 60);
      const seconds = Math.floor(sec_num - hours * 3600 - minutes * 60);

      const time = [];

      if (hours > 0) {
        time.push((hours < 10 ? '0' : '') + hours);
      }

      time.push((minutes < 10 ? '0' : '') + minutes);
      time.push((seconds < 10 ? '0' : '') + seconds);

      if (!self.domElement.counter) {
        return false;
      }

      self.domElement.counter.innerHTML = time.join(':');
      self.discount.counter = sec_num;
      self.saveDiscount();

      if (diff > cd) {
        self.removeDiscount();
        clearInterval(self.timer);
        self.timer = null;
      } // Stop after time
    };

    counter();

    this.timer = setInterval(counter, 1000);
  };

  this.removeDiscount = function () {
    this.domElement.container.parentNode.removeChild(this.domElement.container);

    this.discount = null;
    this.domElement.container = null;
    this.domElement.icon = null;
    this.domElement.counter = null;
    this.domElement.code = null;

    sessionStorage.removeItem(this.storageKey);
  };

  this.saveDiscount = function () {
    sessionStorage.setItem(this.storageKey, JSON.stringify(this.discount));
  };

  this.loadStylesheet = function () {
    if (this.domElement.style) {
      return;
    }

    const css = [
      `#${this.domId.container}, #${this.domId.code}, #${this.domId.counter} { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; }`,
      `#${this.domId.container} { z-index:${core.zIndex}; display:none; visibility: hidden; position:fixed; bottom:-4px; padding: 6px 12px; font-family: "Open Sans",Arial,Helvetica,Sans-Serif; filter: drop-shadow(0 -2px 4px rgba(34,36,38,0.35)); border-radius:4px;}`,
      `#${this.domId.code} { display: inline-block; font-size:20px; vertical-align: middle; margin: 0 10px 0 10px;}`,
      `#${this.domId.icon} { display: inline-block; vertical-align: middle;}`,
      `#${this.domId.icon} svg { fill: currentColor; width:22px; height:22px; }`,
      `#${this.domId.counter} { display: inline-block; user-select: none; cursor: default; font-size:15px; vertical-align: middle;}`,
      `#${this.domId.container}.open {animation-name:mtbigEntrance;animation-duration:1.6s;animation-timing-function:ease-out;visibility:visible!important;display:block!important;}`,
      '@keyframes mtbigEntrance {0%{transform:scale(.3) rotate(8deg) translateX(-30%) translateY(30%);opacity:.2}30%{transform:scale(1.03) rotate(-4deg) translateX(2%) translateY(-2%);opacity:1}45%{transform:scale(.98) rotate(3deg) translateX(0) translateY(0);opacity:1}60%{transform:scale(1.01) rotate(-3deg) translateX(0) translateY(0);opacity:1}75%{transform:scale(.99) rotate(1deg) translateX(0) translateY(0);opacity:1}90%{transform:scale(1.01) rotate(0) translateX(0) translateY(0);opacity:1}100%{transform:scale(1) rotate(0) translateX(0) translateY(0);opacity:1}}',
    ];

    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = this.domId.style;

    if (style.styleSheet) {
      style.styleSheet.cssText = css.join(' ');
    } else {
      style.appendChild(document.createTextNode(css.join(' ')));
    }

    this.domElement.style = style;

    document.body.appendChild(style);
  };

  this.loadDom = function () {
    if (!this.domElement.container) {
      this.domElement.container = document.createElement('div');
      this.domElement.container.id = this.domId.container;
      document.body.appendChild(this.domElement.container);
    }

    if (!this.domElement.icon) {
      this.domElement.icon = document.createElement('div');
      this.domElement.icon.id = this.domId.icon;
      this.domElement.icon.innerHTML =
        '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0" width="293.4" height="293.4" viewBox="0 0 293.4 293.4" xml:space="preserve"><path d="M130.3 162.9c-24.2 0-95.3 0-95.3 0 -7.5 0-13.7 6.2-13.7 13.7v103c0 7.5 6.2 13.7 13.7 13.7 0 0 72.6 0 96.9 0 3.2 0 3.2-3.5 3.2-3.5V167.6C135.1 167.6 135.1 162.9 130.3 162.9zM258.4 162.9c0 0-71.4 0-95.2 0 -5.8 0-4.9 6-4.9 6v121.1c0 0 0 3.4 3.4 3.4 24.2 0 96.7 0 96.7 0 7.5 0 13.7-6.2 13.7-13.7V176.6C272.1 169.1 265.9 162.9 258.4 162.9z"/><path d="M135.1 81.8c0 0 0-4.7-4.6-4.7 -27.5 0-111 0-111 0 -7.5 0-13.7 6.2-13.7 13.7v42.8c0 7.5 6.2 13.7 13.7 13.7 0 0 83.9 0 111.4 0 4.2 0 4.2-3.7 4.2-3.7V81.8L135.1 81.8zM273.9 77.2c0 0-83.5 0-111.4 0 -4.2 0-4.2 4-4.2 4v62.7c0 0 0 3.6 5.2 3.6 27.6 0 110.4 0 110.4 0 7.5 0 13.7-6.2 13.7-13.7V90.9C287.6 83.4 281.4 77.2 273.9 77.2z"/><path d="M88.4 67c-6.3 0-12-0.5-17-1.5 -12.8-2.5-21.6-7.4-26.8-14.8 -4.7-6.7-6.2-15-4.3-24.6C43.5 9.3 54.8 0 72 0c3.6 0 7.7 0.4 11.9 1.3 10.8 2.1 24.7 8.3 37 16.6 21 14 22 22.8 21 28.1 -1.5 7.9-8.9 13.5-22.4 17.2C110.3 65.6 99 67 88.4 67zM72 22c-6.7 0-9 1.9-10.2 8.3 -1 5.3 0.3 7.1 0.7 7.7 1.8 2.5 6.4 4.6 13.1 5.9 3.6 0.7 8 1.1 12.8 1.1 10.6 0 19.9-1.6 26-3.3 0.4-0.1 1.1-0.7 0.4-1.1 -8.1-6.6-22.8-15.3-35.1-17.7C76.8 22.3 74.3 22 72 22z"/><path d="M205.3 67c0 0 0 0 0 0 -10.6 0-21.9-1.4-31-3.9 -13.6-3.7-20.9-9.3-22.4-17.2 -1-5.3 0-14.1 21-28.1 12.4-8.3 26.2-14.5 37-16.6 4.3-0.8 8.3-1.3 11.9-1.3 17.2 0 28.5 9.3 31.8 26.1 1.9 9.6 0.4 17.9-4.3 24.6 -5.3 7.5-14.1 12.4-26.8 14.9C217.3 66.5 211.6 67 205.3 67zM179 40.5c-0.7 0.4-0.4 1.1 0 1.2 6.2 1.8 15.6 3.4 26.2 3.4 4.8 0 9.2-0.4 12.8-1.1 6.7-1.3 11.3-3.4 13.1-5.9 0.4-0.6 1.7-2.4 0.7-7.7C230.6 23.9 228.3 22 221.7 22c-2.2 0-4.8 0.3-7.7 0.8C201.7 25.2 187.1 33.9 179 40.5z"/></svg>';
      this.domElement.container.appendChild(this.domElement.icon);
    }

    if (!this.domElement.code) {
      this.domElement.code = document.createElement('div');
      this.domElement.code.id = this.domId.code;
      this.domElement.container.appendChild(this.domElement.code);
    }

    if (!this.domElement.counter) {
      this.domElement.counter = document.createElement('div');
      this.domElement.counter.id = this.domId.counter;
      this.domElement.container.appendChild(this.domElement.counter);
    }
  };

  this.fetchFromStorage = function () {
    if (sessionStorage.getItem(this.storageKey)) {
      this.setDiscount(JSON.parse(sessionStorage.getItem(this.storageKey)));
    }
  };

  this.preventOffscreen = function (container) {
    const boundingRect = container.getBoundingClientRect();

    if (boundingRect.left < 0) {
      core.setStyle(container, 'left', 0);
      core.setStyle(container, 'right', 'auto');
    }

    if (boundingRect.left + boundingRect.width > core.window.width) {
      core.setStyle(container, 'right', 0);
      core.setStyle(container, 'left', 'auto');
    }
  };

  this.construct();
};

core.timer = function (obj) {
  this.isRunning = false; // is timer currently running
  this.interval = 500; // default interval of event calls

  this.funcRef = null; // reference to called function
  this.scope = this;

  this._timerId = 0; // global timer id

  /**
   * @constructor
   * @param {Object} obj - construction parameter
   */
  this.construct = function (obj) {
    this.mixIn(obj, this);
    // find smallest not used timer id
    while (document[`myTimer${this._timerId}`] != null) this._timerId++;
    document[`myTimer${this._timerId}`] = this;
  };

  /**
   * mix a given object into this object
   * @param {Object} obj - given object with parameters
   */
  this.mixIn = function (obj, scope) {
    if (!scope) scope = this;
    let item = null;
    for (item in obj) {
      scope[item] = obj[item];
    }
  };

  /**
   * starts timer
   * @param {Int} interval - time in milliseconds for time interval
   * @param {Node} scope - the execution scope of the reference function
   * @param {Function} funcRef - function reference of function to be called on time event
   */
  this.start = function (funcRef, scope, interval) {
    if (this.isRunning) return false;

    if (interval) this.interval = interval;

    this.scope = scope;
    this.funcRef = funcRef;
    this.isRunning = true;
    this.startTimer();
  };

  /**
   * starts a new time event call with given time interval
   */
  this.startTimer = function () {
    if (!this.isRunning) return;
    window.setTimeout(
      `document['myTimer${this._timerId}'].timedHandler()`,
      this.interval,
    );
  };

  /**
   * stopps the timer
   */
  this.stopp = function () {
    this.isRunning = false;
  };

  /**
   * timed event handler. will be called on each time event
   */
  this.timedHandler = function () {
    if (this.isRunning) {
      if (this.funcRef) {
        this.funcRef.apply(this.scope);
      } else this.stopp();

      // do next timer call
      this.startTimer();
    }
  };

  // constructor call
  this.construct(obj);
};

core.dynamic_field = function (name, value) {
  core.dynamicFields = core.dynamicFields || {};
  core.dynamicFields[name] = value;
};

core.push = function (state) {
  core.pushEnabled = !!state;
};

core.shopify = function () {
  core.isShopify = true;
};

core._segments = function (segments) {
  this.segments = segments;
};

core.mapMethod = function (method, args) {
  switch (method) {
    case 'shopify':
      core.shopify(...args);
      break;
    case 'create':
      core.create(...args);
      break;
    case 'setVisit':
      core.setVisit(...args);
      break;
    case 'initCampaigns':
      core.initCampaigns(...args);
      break;
    case 'dynamic_field':
      core.dynamic_field(...args);
      break;
    case 'segments':
      core._segments(...args);
      break;
    case 'pageview':
      core._pageview(...args);
      break;
    case 'loadPushNotification':
      core.loadPushNotification(...args);
      break;
    case 'set':
      core._set(...args);
      break;
    case 'push':
    case 'webpush':
      core.push(...args);
      break;
    case 'addTag':
      mtPush('addTag', ...args);
      break;
    case 'removeTag':
      mtPush('removeTag', ...args);
      break;
    case 'traits':
      mtPush('setTraits', ...args);
      break;
    case 'setTraits':
      mtPush('setTraits', ...args);
      break;
    case 'setLanguage':
      mtPush('setLanguage', ...args);
      break;
    case 'ready':
      core._ready(...args);
      break;
    case 'webpush:prompt:show':
      mtPush('triggerPrompt', ...args);
      break;
    case 'overlay:campaign:show':
      core.runner.run(core.triggerManualCampaign, core, args);
      break;
    case 'track':
      core.runner.run(core._trackEvent, core, args);
      break;
    case 'on':
      core._onEvent(...args);
      break;
  }
};

core._onEvent = function (event, fn) {
  core.emitter.on(event, fn);
};

core._trackEvent = function (event, properties) {
  if (!core.websiteId || !core.uid) {
    return;
  }
  this.analytics.track(
    core.websiteId,
    core.uid,
    core.contactId,
    event,
    properties,
  );
};

core._ready = function (cb) {
  mtPush('ready', cb);
};

core._set = function (property, value) {
  switch (property) {
    case 'revision':
      core.revision = value;
      break;
    case 'device':
      core.device = value;
      break;
    case 'pageview':
      core.pageview = value;
      break;
    case 'aggression_level':
      core.aggression_level = value;
      break;
    case 'userId':
      if (
        value &&
        value != 'null' &&
        value != '0' &&
        value != 'nil' &&
        value != 'undefined'
      ) {
        mtPush('set', 'userId', value);
      }
    case 'send_ga':
      core.send_ga = value;
      break;
    case 'websiteId':
      core.websiteId = value;
      break;
    case 'language':
      core.language = value;
      break;
    case 'promoBarTopFixed':
      core.promoBarFixed = value;
      break;
    case 'fixedHeaderSelector':
      core.fixedHeaderSelector = value;
      break;
    case 'preload':
      core.preloadEnabled = !!value;
      break;
    case 'zIndex':
      core.zIndex = value;
      break;
  }
};

core._pageview = function () {
  core.removePreloadContainer();
  core.activeCampaigns = [];
  core.campaigns = [];
  core.getCampaigns();
};

core.create = function (websiteId, newConfig) {
  if (core.created) return;

  core.created = true;
  core.config = {};
  if (typeof newConfig !== 'undefined') {
    core.config = newConfig;
  }
  core.language = 'en';
  core.preloadEnabled = true;
  core.zIndex = 2147483647;
  core.pushEnabled = true;
  core.website_id = websiteId;
  core.tabId = Math.floor(Math.random() * 1e15 + 1e5);
  core.activeTabs = 1;
  core.segments = core.segments || [];
  core.dynamicFields = core.dynamicFields || {};
  core.onShow = core.onShow || false;
  core.campaigns = [];
  core.cappingCookie = false;
  core.uid = null;
  core.contactId = null;
  core.visit = Math.round(+new Date() / 1000);
  core.isExitIntent = false;
  core.visitCount = 1;
  core.seconds = 0;
  core.visitTime = 0;
  core.pageview = 1;
  core.activeCampaigns = {};
  core.mouseMovement = [];
  core.preview = false;
  core.device = false;
  core.window = core.getWindowSize();
  core.aggression_level = 0;
  core.isShopify = core.isShopify || false;
  core.cdn_domain = config.cdn_domain;
  core.send_ga = 1;
  core.promoBarFixed = true;
  core.fixedHeaderSelector = null;
  core.fixedHeaderOriginalTop = 0;

  const { serve_domain } = config;
  const { serve_path } = config;

  core.options = {
    serve_domain,
    url_init: `${serve_path}/public/init.php?v=4&website_id=${core.website_id}`,
    url_close: `${serve_path}/log/close?website_id=${core.website_id}`,
    url_serve: `//${core.cdn_domain}/serve/display`,
    url_cart: `${serve_path}/log/cart?website_id=${core.website_id}`,
    url_pageview: `${serve_path}/log/pageview?website_id=${core.website_id}`,
    url_impression: `${serve_path}/log/view?website_id=${core.website_id}`,
    website_domain: core.getDomainName(),
    cookie_domain: core.getDomainName(),
  };

  core.setUID();
  core.runner.run(core.syncContactId, core);
  core.updateTabs();
  core.discount = new DiscountRibbon();

  if (core.isInactive()) {
    return;
  }

  if (core.isShopify) {
    try {
      const xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = function () {
        if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          const cart = JSON.parse(xmlHttp.responseText);
          if (cart.item_count > 0) {
            core.segments.push('has products in cart');
          }
        }

        if (xmlHttp.readyState == 4) {
          core.getCampaigns();
        }
      };
      xmlHttp.open('GET', '/cart.js', true);
      xmlHttp.send(null);
    } catch (e) {}
    core.updateShopifyCart();
  } else {
    core.getCampaigns();
  }

  const timer = new core.timer({ interval: 100 });

  timer.start(core.tryCampaigns);

  window.setInterval(core.updateTabs, 3000);

  // Set events
  core.addEvent(window, 'message', creative.messageHandler, false);
  core.addEvent(window, 'message', core.messageHandler, false);

  core.addEvent(window, 'storage', (e) => {
    if (!e.key) {
      return false;
    }

    if (e.key.startsWith('mt_impression_')) {
      core.crossTabImpression(e.key, e.newValue);
    }

    if (
      e.key == `mt_tab_${core.tabId}` ||
      (e.newValue !== null && e.oldValue !== null)
    ) {
      return false;
    }

    core.updateTabs();
  });

  core.addEvent(window, 'resize', () => {
    creative.onResize();
  });

  core.addEvent(window, 'orientationchange', () => {
    creative.onResize();
  });

  core.addEvent(window, 'focus', (e) => {
    timer.start(core.tryCampaigns);
  });

  core.addEvent(window, 'blur', (e) => {
    timer.stopp();
    core.logPageTime();
  });

  core.addEvent(window, 'beforeunload', (e) => {
    localStorage.removeItem(`mt_tab_${core.tabId}`);
    core.logPageTime();
  });

  core.addEvent(document, 'beforeunload', (e) => {
    timer.stopp();
    core.logPageTime();
  });

  core.addEvent(document, 'mouseover', (e) => {
    core.isExitIntent = false;
    timer.start(core.tryCampaigns);
  });

  core.addEvent(document, 'mouseenter', (e) => {
    core.isExitIntent = false;
  });

  core.addEvent(document, 'mouseout', (e) => {
    e = e || window.event;
    const from = e.relatedTarget || e.toElement;

    if (
      (!from || from.nodeName == 'HTML') &&
      (e.clientY <= 100 || core.aggression_level == 2) &&
      core.mouseMovement.length == 10 &&
      (core.mouseMovement[0].y < core.mouseMovement[9].y ||
        core.aggression_level == 2) &&
      (core.mouseMovement[9].y - core.mouseMovement[0].y > 15 ||
        core.aggression_level == 2) &&
      (core.activeTabs == 1 || core.aggression_level > 0)
    ) {
      core.isExitIntent = true;
    }

    core.logPageTime();
  });

  core.addEvent(document, 'mousemove', (e) => {
    core.logMousemove(e);
  });
};

core.validOrigin = function (origin) {
  return (
    origin.startsWith(`https://${config.cdn_domain}`) ||
    origin.startsWith(`http://${config.cdn_domain}`) ||
    origin.startsWith(`http://${config.serve_domain}`) ||
    origin.startsWith(`https://${config.serve_domain}`)
  );
};

core.moveFixedHeader = function (offset) {
  const header = document.querySelector(core.fixedHeaderSelector);

  if (header === null) {
    return;
  }

  core.fixedHeaderOriginalTop = header.style.top;

  header.style.top = offset;
};

core.resetFixedHeader = function () {
  const header = document.querySelector(core.fixedHeaderSelector);

  if (header === null) {
    return;
  }

  header.style.top = core.fixedHeaderOriginalTop;
};

let creative = {
  campaign: false,
};

creative._getCreativeIframe = function (campaignId) {
  return document.getElementById(
    core.domHash(
      'creative-iframe',
      campaignId === undefined ? creative.campaign.id : campaignId,
    ),
  );
};

creative._getCreativeContainer = function (campaignId) {
  return document.getElementById(
    core.domHash(
      'creative-container',
      campaignId === undefined ? creative.campaign.id : campaignId,
    ),
  );
};

creative._bindCampaign = function (campaignId) {
  creative.campaign = core.activeCampaigns[campaignId] || false;

  return creative.campaign;
};

creative.creativeClose = function (message) {
  const { options } = message;

  core.logPixel(
    `${core.options.url_close}&creative_id=${
      creative.campaign.creative_id
    }&campaign_id=${creative.campaign.id}&pageview=${
      core.pageview
    }&url=${encodeURIComponent(core.getPageUrl())}`,
  );

  if (options.closeable) {
    if (creative.campaign.type == 'promo-bar') {
      document.body.classList.remove(creative.promoReadyClass);
      document.body.classList.remove(creative.promoGoClass);
      core.resetFixedHeader();
    }

    creative
      ._getCreativeContainer()
      .parentNode.removeChild(creative._getCreativeContainer());

    if (creative.campaign.manual_trigger_id !== null) {
      core.insertCampaignFrame(creative.campaign);
    }

    delete core.activeCampaigns[creative.campaign.id];
  }

  creative.campaign = null;
};

creative.creativeClick = function () {
  core.gaEvent('click', creative.campaign.id, creative.campaign.name);
  core.emitter.emit('Campaign Clicked', creative.campaign);
  // core.analytics.track(
  //   core.websiteId,
  //   core.uid,
  //   core.contactId,
  //   'On-site Promotion Clicked',
  //   {
  //     campaignId: creative.campaign.id,
  //     creativeId: creative.id,
  //     creativeType: creative.campaign.type,
  //   },
  // );
};

creative.creativeLead = function (lead) {
  core.emitter.emit('Lead Submitted', lead);
  core.gaEvent('lead', creative.campaign.id, creative.campaign.name);
};

/**
 * Adjusts the creative properties on expand.
 */
creative.creativeResize = function (options) {
  const container = creative._getCreativeContainer();

  core.removeStyle(container, 'left');
  core.removeStyle(container, 'right');
  core.removeStyle(container, 'top');
  core.removeStyle(container, 'bottom');

  core.setStyle(container, 'display', 'block');
  core.setStyle(container, 'width', options.width);
  core.setStyle(container, 'height', options.height);
  core.setStyle(container, 'margin-top', options.marginTop);
  core.setStyle(container, 'margin-left', options.marginLeft);
  core.setStyle(container, options.vAlign, options.vAlignValue);
  core.setStyle(container, options.hAlign, options.hAlignValue);

  if (options.position === 2) {
    core.moveFixedHeader(options.height);
  }

  if (!container.getAttribute(hashDataAttr('data-modal-open'))) {
    const _iframe = creative._getCreativeIframe();
    setTimeout(() => {
      _iframe.focus();
    }, 100);
  }

  container.setAttribute(hashDataAttr('data-modal-open'), 1);
  container.setAttribute(hashDataAttr('data-bootstraped'), 1);
  creative.onResize(options.scale);
};

creative.promoBarResize = function (options) {
  const container = creative._getCreativeContainer();
  const closeBtnHeight = 45;
  const height =
    options.slider && core.device == 'phone' && options.position === 'bottom'
      ? options.height + closeBtnHeight
      : options.height;

  if (!core.promoBarFixed && options.position === 'top') {
    core.setStyle(container, 'position', 'absolute');
  }

  const bootstraped = !!container.getAttribute(
    hashDataAttr('data-bootstraped'),
  );

  container.setAttribute(hashDataAttr('data-position'), options.position);
  container.setAttribute(hashDataAttr('data-modal-open'), options.modal_open);
  container.setAttribute(hashDataAttr('data-height'), height);
  container.setAttribute(hashDataAttr('data-slider'), options.slider);

  if (options.position === 'top') {
    core.moveFixedHeader(options.height);
  }

  if (!bootstraped) {
    core.setStyle(container, 'display', 'block');

    container.classList.add(`mt-promo-bar--${options.position}`);
    container.classList.add(`mt-promo-bar--${core.device}`);

    core.removeStyle(container, 'top');
    core.setStyle(container, options.position, `-${height}px`);
    core.setStyle(container, 'width', '100%');
    core.setStyle(container, 'height', `${height}px`);

    container.setAttribute(hashDataAttr('data-bootstraped'), 1);

    // update top body padding height
    if (options.position === 'top') {
      const styleLoaded = document.getElementById(
        core.domHash('promo-stylesheet'),
      );
      const { cssRules } = styleLoaded.sheet;
      for (let i = 0; i < cssRules.length; i++) {
        if (
          cssRules[i].selectorText ===
          `body.${core.domHash('promo-go')}::before`
        ) {
          styleLoaded.sheet.cssRules[1].style.height = `${height}px`;
        }
      }
    }

    setTimeout(() => {
      container.style.transition = /Edge/i.test(navigator.userAgent)
        ? ''
        : `${options.position} 500ms cubic-bezier(.455,.03,.515,.955)`;
      container.style[options.position] = '0px';

      if (
        /Edge/i.test(navigator.userAgent) &&
        options.position == 'bottom' &&
        core.device == 'desktop' &&
        !options.slider
      ) {
        const iframe = creative._getCreativeIframe();
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        const divs = iframeDocument.getElementsByTagName('div');
        let redraws = 0;

        const forceRedraw = function (element) {
          if (!element) {
            return;
          }

          const n = document.createTextNode(' ');
          const { display } = element.style; // don't worry about previous display style

          element.appendChild(n);
          core.setStyle(element, 'display', 'none');
          const redraw = this.offsetHeight;
          core.setStyle(element, 'display', display);

          setTimeout(() => {
            core.setStyle(element, 'display', display);
            n.parentNode.removeChild(n);
          }, 1); // you can play with this timeout to make it as short as possible
        };

        const redraw = function () {
          for (let i = 0; i < divs.length; i++) {
            forceRedraw(divs[i]);
          }
          redraws++;
        };

        redraw();
        setInterval(redraw, 100);
      }

      setTimeout(() => {
        core.removeStyle(container, 'transition');
      }, 1000);

      if (options.position == 'top') {
        document.body.classList.add(creative.promoGoClass);
      }
    }, 100);
  } else {
    creative.onResize();
  }
};

creative.onResize = function (scale = true) {
  for (const index in core.activeCampaigns) {
    if (core.activeCampaigns.hasOwnProperty(index)) {
      const campaign = core.activeCampaigns[index];
      const container = creative._getCreativeContainer(campaign.id);

      core.setStyle(container, 'display', 'block');

      if (campaign.type == 'overlay') {
        if (scale) {
          creative._getCreativeIframe(campaign.id).contentWindow.postMessage(
            JSON.stringify({
              action: 'scale',
              options: {
                width: window.innerWidth,
                height: window.innerHeight,
              },
            }),
            '*',
          );
        }
      } else if (core.device != 'desktop') {
        const modalOpen =
          container.getAttribute(hashDataAttr('data-modal-open')) == 1;
        const promoHeight =
          container.getAttribute(hashDataAttr('data-height')) ?? 42;

        let height = modalOpen ? window.innerHeight : promoHeight;

        core.setStyle(container, 'height', `${height}px`);
      }
    }
  }
};

creative.bootstrap = function (campaign) {
  if (!core.preloadEnabled) {
    core.insertCampaignFrame(campaign);
  }

  creative.campaign = campaign;
  let zIndex = core.zIndex;

  if (creative.campaign.type == 'promo-bar') {
    creative.promoGoClass = core.domHash('promo-go');
    creative.promoReadyClass = core.domHash('promo-ready');

    const styleId = core.domHash('promo-stylesheet');
    const styleLoaded = document.getElementById(styleId);
    if (!styleLoaded) {
      const css = `body.${creative.promoReadyClass}:before{transition:height 500ms cubic-bezier(.455,.03,.515,.955);content: " ";display:block;visibility: hidden;height:0;padding:0;margin:0;} body.${creative.promoGoClass}:before{height: 42px;}`;
      const style = document.createElement('style');
      style.type = 'text/css';
      style.id = styleId;
      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      document.body.appendChild(style);
    }

    document.body.classList.add(creative.promoReadyClass);

    zIndex = core.zIndex - 2;
  }

  const container = creative._getCreativeContainer();

  core.setStyle(container, 'z-index', zIndex);
  core.setStyle(container, 'left', 0);
  core.setStyle(container, 'top', 0);
  core.setStyle(container, 'display', 'none');
  core.setStyle(container, 'position', 'fixed');

  // document.body.appendChild(container);

  const jsonMessage = {
    action: 'bootstrap',
    options: {
      dynamicFields: core.dynamicFields,
      variables: {
        pageURL: core.getPageUrl(),
      },
      width: window.innerWidth,
      height: window.innerHeight,
    },
  };

  const iframe = creative._getCreativeIframe();

  iframe.onload = function () {
    iframe.contentWindow.postMessage(JSON.stringify(jsonMessage), '*');
  };

  iframe.contentWindow.postMessage(JSON.stringify(jsonMessage), '*');

  if (!core.preview) {
    core.setImpression(creative.campaign);
  }
};

creative.evalJS = function (jsString) {
  const func = new Function(jsString);
  func();
};

creative.messageHandler = function (event) {
  if (!core.validOrigin(event.origin)) {
    return;
  }

  let message;

  try {
    message = JSON.parse(event.data);
  } catch (e) {
    return;
  }

  if (
    !message ||
    !message.campaignId ||
    !creative._bindCampaign(message.campaignId)
  ) {
    return;
  }

  switch (message.action) {
    case 'resize':
      creative.creativeResize(message.options);
      break;
    case 'promo-bar-update':
      creative.promoBarResize(message.options);
      break;
    case 'close':
      creative.creativeClose(message);
      break;
    case 'click':
      creative.creativeClick();
      break;
    case 'evalJS':
      creative.evalJS(message.options);
      break;
    case 'redirect':
      window.focus();
      setTimeout(() => {
        window.open(
          message.options.url,
          message.options.blank ? '_blank' : '_top',
        );
      }, 200);
      break;
    case 'set-cookie':
      Cookies.set(message.options.name, message.options.value, {
        expires: message.options.ttl / (24 * 60 * 60) || 90,
        domain: core.options.cookie_domain,
      });
      break;
    case 'lead':
      creative.creativeLead(message.data || {});
      break;
  }
};

const exitIntentDetect = new ExitIntent(window, document);
exitIntentDetect.onExit(() => {
  if (core.device == 'phone' || core.device == 'tablet') {
    core.isExitIntent = true;
  }
});

export default core;
