import { v4 as uuid } from 'uuid';
import IdentifyEvent from './IdentifyEvent';
import TrackEvent from './TrackEvent';

class Analytics {
  constructor(apiEndpoint) {
    this.apiEndpoint = apiEndpoint;
  }

  track(websiteId, anonymousId, contactId, event, properties) {
    this.enqueue(
      new TrackEvent({
        id: uuid(),
        event,
        websiteId,
        anonymousId,
        contactId: contactId || undefined,
        properties,
      }),
    );
  }

  identify(websiteId, anonymousId, contactId) {
    this.enqueue(
      new IdentifyEvent({
        id: uuid(),
        websiteId,
        anonymousId,
        contactId: contactId || undefined,
      }),
    );
  }

  enqueue(event) {
    this.sendJson(
      `${this.apiEndpoint}/${event.action}`,
      event.toJSON(),
      {},
      10 * 1000,
      (err, res) => {
        // console.log('sent', event.toJSON(), [err, res]);
      },
    );
  }

  sendJson(url, obj, headers, timeout, fn) {
    const req = new XMLHttpRequest();
    req.onerror = fn;
    req.onreadystatechange = () => {
      if (req.readyState === 4) {
        // Fail on 429 and 5xx HTTP errors
        if (req.status === 429 || (req.status >= 500 && req.status < 600)) {
          fn(new Error(`HTTP Error ${req.status} (${req.statusText})`));
        } else {
          fn(null, req);
        }
      }
    };

    req.open('POST', url, true);

    if (timeout > 0) {
      req.timeout = timeout;
      req.ontimeout = fn;
    }

    req.setRequestHeader('Content-Type', 'application/json');

    for (const k in headers) {
      req.setRequestHeader(k, headers[k]);
    }
    req.send(JSON.stringify(obj));
  }
}

export default Analytics;
