export default class TrackEvent {
  constructor({
    id,
    event,
    websiteId,
    anonymousId,
    contactId,
    userId,
    properties = {},
  } = {}) {
    this.id = id;
    this.action = 'track';
    this.event = event;
    this.websiteId = websiteId;
    this.anonymousId = anonymousId;
    this.contactId = contactId;
    this.userId = userId;
    this.properties = properties;
    this.timestamp = new Date();
  }

  toJSON() {
    return { ...this, timestamp: this.timestamp.toISOString() };
  }
}
