class Runner {
  constructor() {
    this.enqueue = true;
    this.queue = [];
  }

  run(method, context, params) {
    const wrapped = Runner.wrap(method, context, params);
    if (this.enqueue) {
      this.queue.push(wrapped);
    } else {
      wrapped();
    }
  }

  disableQueue() {
    this.enqueue = false;
    while (this.queue.length > 0) {
      this.queue.shift()();
    }
  }

  static wrap(fn, context, params) {
    return () => {
      fn.apply(context, params);
    };
  }
}

export default Runner;
