export default class ExitIntent {
  constructor(window, document) {
    this.window = window;
    this.document = document;
    this.heightWithToolbar = null;
    this.heightWithoutToolbar = null;
    this.toolbarVisible = true;
    this.toolbarWasHidden = false;
    this.scrollingUp = false;
    this.timer = null;

    this.exitCallbacks = [];
    this.init();
  }

  init() {
    this.watchToolbar();
    this.checkActivity();
    this.detectScrollUp();
  }

  setToolbarHeight() {
    if (!this.heightWithToolbar) {
      this.heightWithToolbar = this.window.innerHeight;
    }

    if (
      !this.heightWithoutToolbar &&
      this.window.innerHeight > this.heightWithToolbar
    ) {
      this.heightWithoutToolbar = this.window.innerHeight;
    }
  }

  setToolbarVisibility() {
    this.toolbarVisible = Boolean(
      this.window.innerHeight === this.heightWithToolbar,
    );
  }

  detectScrollUp() {
    let lastScroll = this.window.scrollY;

    this.window.addEventListener('scroll', () => {
      const scroll = window.scrollY;

      if (scroll < lastScroll) {
        this.scrollingUp = true;
      } else {
        this.scrollingUp = false;
      }

      lastScroll = scroll <= 0 ? 0 : scroll;
    });
  }

  watchToolbar() {
    setInterval(() => {
      this.setToolbarHeight();
      this.setToolbarVisibility();

      if (this.heightWithoutToolbar) {
        this.toolbarWasHidden = true;
      }
    }, 100);
  }

  onExit(cb) {
    this.exitCallbacks.push(cb);
  }

  triggerExit() {
    if (this.toolbarVisible && this.toolbarWasHidden && this.scrollingUp) {
      this.exitCallbacks.forEach((cb) => cb());
    }
  }

  resetTimer() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.triggerExit(), 1500);
  }

  checkActivity() {
    const events = ['touchstart', 'toucheend', 'touchmove'];

    events.forEach((event) => {
      this.document.addEventListener(event, () => this.resetTimer(), true);
    });
  }
}
