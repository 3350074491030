import md5 from 'spark-md5';

function supportsSafariPush() {
  return window.safari && typeof window.safari.pushNotification !== 'undefined';
}

function supportsVapidPush() {
  return (
    typeof PushSubscriptionOptions !== 'undefined' &&
    PushSubscriptionOptions.prototype.hasOwnProperty('applicationServerKey')
  );
}

export function isPushNotificationsSupported() {
  return supportsVapidPush() || supportsSafariPush();
}

export function hashDataAttr(value) {
  return `data-${md5.hash(value.substr(0, 16))}`;
}
