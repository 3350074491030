export default class IdentifyEvent {
  constructor({
    id,
    websiteId,
    anonymousId,
    contactId,
    userId,
    traits = {},
  } = {}) {
    this.id = id;
    this.action = 'identify';
    this.websiteId = websiteId;
    this.anonymousId = anonymousId;
    this.contactId = contactId;
    this.userId = userId;
    this.traits = traits;
    this.timestamp = new Date();
  }

  toJSON() {
    return { ...this, timestamp: this.timestamp.toISOString() };
  }
}
