import core from './core';

const Queue = function (events) {
  this.handle = function (args) {
    var args = Array.prototype.slice.call(args);

    let method = args.shift();

    try {
      if (typeof method === 'function') {
        args = [method];
        method = 'ready';
      }

      core.mapMethod(method, args);
    } catch (e) {
      console.log(method, args, e);
    }
  };

  const self = this;

  events.forEach((args) => {
    self.handle(args);
  });
};

if (window.mtsdk_tracker && window.mtsdk_tracker.length > 0) {
  core.globalName = window.mtsdk_tracker;
}

if (window.mtsdk) {
  console.error('MT tracking code is duplicated. Remove duplicate `https://cdn.mxapis.com/mt.js`.');
} else {
  window.mtsdk = core;

  window[core.globalName] =
    window[core.globalName] ||
    function () {
      (window[core.globalName].q = window[core.globalName].q || []).push(
        arguments,
      );
    };
  window[core.globalName].l = +new Date();

  window.mtPush =
    window.mtPush ||
    function () {
      (window.mtPush.q = window.mtPush.q || []).push(arguments);
    };
  window.mtPush.l = +new Date();

  const queue = new Queue(window[core.globalName].q || []);

  window[core.globalName] = function (cb) {
    queue.handle(arguments);
  };
}
